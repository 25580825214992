

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import styles from './index.module.css'

import {PageTitle} from "../components/block";
import {useTranslation} from "next-i18next";


export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale)),
        },
    };
}

export default function About() {
    const { t } = useTranslation();
  return (
    <div className={styles.container}>
        <div className={`w-full pl-12 pr-12 py-12 ${styles.pagebg} `}>
            {/*<PageTitle>{t(`navmenu.about`)}</PageTitle>*/}
            <PageTitle>404 - Page not found</PageTitle>
        </div>
    </div>
  )
}